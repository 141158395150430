import { createRouter, createWebHistory } from "vue-router";
import Firebase from "@/firebase.js";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Home.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("@/views/Login.vue"),
    meta: {
      isLoginPage: true,
      layout: "LoggedOutLayout",
    },
  },
  {
    path: "/professores",
    name: "professores",
    component: () => import("@/views/Teachers.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/alunos",
    name: "alunos",
    component: () => import("@/views/Students.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/config",
    name: "config",
    component: () => import("@/views/Config.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/chamada/:groupId",
    name: "chamada",
    component: () => import("@/views/GroupRollCall.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/notas/:groupId",
    name: "notas",
    component: () => import("@/views/GroupGrades.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/turmas",
    name: "turmas",
    component: () => import("@/views/Groups.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/minhas-turmas",
    name: "minhas-turmas",
    component: () => import("@/views/MyGroups.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);

  if (requiresAuth && !Firebase.getAuth().currentUser) {
    next("/login");
    return;
  }

  const isLoginPage = to.matched.some((x) => x.meta.isLoginPage);

  if (isLoginPage && Firebase.getAuth().currentUser) {
    next("/");
    return;
  }

  next();
  return;
});

export default router;
