import { initializeApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  addDoc,
  getDocs,
  where,
  orderBy,
  getDoc,
  collection,
  setDoc,
  query,
  deleteDoc,
} from "firebase/firestore";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyAR3lasS4PXNzUBMqJwSwX_w1w2g5-i8QY",
  authDomain: "rollcall-66972.firebaseapp.com",
  projectId: "rollcall-66972",
  storageBucket: "rollcall-66972.appspot.com",
  messagingSenderId: "1091809052748",
  appId: "1:1091809052748:web:b8379a4b3c68f195e9cce9",
  measurementId: "G-C8PHNSTLK5",
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const firestore = getFirestore(firebaseApp);
// const analytics = getAnalytics(app);

const _getAuth = () => {
  return auth;
};

const _getFirestore = () => {
  return firestore;
};

const _onAuthStateChanged = (handler, error, completed) => {
  return onAuthStateChanged(auth, handler, error, completed);
};

const _doc = (path) => {
  return doc(firestore, path);
};

const _collection = (path) => {
  return collection(firestore, path);
};

const _addDoc = (path, data) => {
  return addDoc(_collection(path), data);
};

const _deleteDoc = (path) => {
  return deleteDoc(_doc(path));
};

const _getDocs = (path, ...queryConstraints) => {
  return getDocs(_query(path, ...queryConstraints));
};

const _getDoc = (path) => {
  return getDoc(_doc(path));
};

const _setDoc = (path, data, options) => {
  return setDoc(_doc(path), data, { ...options, merge: true });
};

const _query = (path, ...queryConstraints) => {
  return query(_collection(path), ...queryConstraints);
};

const _signInWithEmailAndPassword = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

const _signOut = () => {
  return signOut(auth);
};

const _createUserWithEmailAndPassword = (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

const Firebase = {
  collections: {
    TEACHERS: "professores",
    GROUPS: "turmas",
    STUDENTS: "alunos",
    GROUP_STUDENTS: (groupId) => `turmas/${groupId}/alunos`,
    GROUP_CLASSES: (groupId) => `turmas/${groupId}/aulas`,
  },
  getAuth: _getAuth,
  getFirestore: _getFirestore,
  onAuthStateChanged: _onAuthStateChanged,
  doc: _doc,
  addDoc: _addDoc,
  deleteDoc: _deleteDoc,
  collection: _collection,
  getDocs: _getDocs,
  getDoc: _getDoc,
  setDoc: _setDoc,
  query: _query,
  where,
  orderBy,
  signInWithEmailAndPassword: _signInWithEmailAndPassword,
  signOut: _signOut,
  createUserWithEmailAndPassword: _createUserWithEmailAndPassword,
  API_URL: (path) =>
    `https://us-central1-rollcall-66972.cloudfunctions.net/app/${path}`,
};

export default Firebase;
