import { createApp } from "vue";
import App from "./App.vue";
import "./assets/tailwind.css";
import "@vueform/multiselect/themes/default.css";
import store from "./store";
import router from "./router";
import Firebase from "@/firebase";
import VueformMultiselect from "@vueform/multiselect";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";

let vueApp;

Firebase.onAuthStateChanged(() => {
  if (!vueApp) {
    vueApp = createApp(App)
      .use(router)
      .use(store)
      .use(VueToast)
      .component("Multiselect", VueformMultiselect)
      .mount("#app");
  }
});
