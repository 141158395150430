<template>
  <component :is="layout" />
</template>

<script>
import LoggedInLayout from "@/layouts/LoggedInLayout.vue";
import LoggedOutLayout from "@/layouts/LoggedOutLayout.vue";

export default {
  components: {
    LoggedInLayout,
    LoggedOutLayout,
  },
  data() {
    return {
      layout: null,
    };
  },
  watch: {
    $route(to) {
      if (to.meta.layout !== undefined) {
        this.layout = to.meta.layout;
      } else {
        this.layout = "LoggedInLayout";
      }
      document.title = to.meta.title || "[Chamada] Escola Araruna";
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
