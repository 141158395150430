<template>
  <Navbar />
  <div class="relative top-16 p-4 h-full">
    <router-view />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  components: {
    Navbar,
  },
};
</script>
