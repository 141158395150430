<template>
  <nav
    class="
      flex
      fixed
      w-full
      items-center
      justify-between
      px-6
      h-16
      bg-white
      text-gray-700
      border-b border-gray-200
      z-10
    "
  >
    <div class="flex items-center">
      <button class="mr-2" aria-label="Open Menu" @click="drawer">
        <svg
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
          class="w-8 h-8"
        >
          <path d="M4 6h16M4 12h16M4 18h16"></path>
        </svg>
      </button>
      <img
        src="@/assets/img/logo_tipografico.svg"
        alt="Logo"
        class="h-auto w-24"
      />
    </div>
    <div class="flex items-center">
      <div class="block flex justify-between bg-transparent">
        <div class="flex items-center justify-center p-4">
          <span class="rounded-md shadow-sm"
            ><button class="hover" type="button" @click="signOut">
              <img
                class="h-5 w-5"
                src="@/assets/img/logout.svg"
                alt=""
              /></button
          ></span>
        </div>
      </div>
      <!--
      <div class="hidden lg:block lg:flex lg:justify-between lg:bg-transparent">
        <div class="flex items-center justify-center p-12">
          <div class="relative inline-block text-left dropdown">
            <span class="rounded-md shadow-sm"
              ><button
                class="inline-flex justify-center w-full px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                type="button"
                aria-haspopup="true"
                aria-expanded="true"
                aria-controls="headlessui-menu-items-117"
              >
                <span>Opções</span>
                <svg
                  class="w-5 h-5 ml-2 -mr-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg></button
            ></span>
            <div
              class="opacity-0 invisible dropdown-menu transition-all duration-300 transform origin-top-right -translate-y-2 scale-95"
            >
              <div
                class="absolute right-0 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
                aria-labelledby="headlessui-menu-button-1"
                id="headlessui-menu-items-117"
                role="menu"
              >
                <div class="px-4 py-3">
                  <p class="text-sm leading-5">Logado como</p>
                  <p
                    class="text-sm font-medium leading-5 text-gray-900 truncate"
                  >
                    {{ userEmail }}
                  </p>
                </div>
               
                <div class="py-1">
                  <a
                    href="javascript:void(0)"
                    tabindex="3"
                    class="text-gray-700 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left"
                    role="menuitem"
                    @click="signOut"
                    >Deslogar</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      -->
    </div>

    <transition
      enter-class="opacity-0"
      enter-active-class="ease-out transition-medium"
      enter-to-class="opacity-100"
      leave-class="opacity-100"
      leave-active-class="ease-out transition-medium"
      leave-to-class="opacity-0"
    >
      <div
        @keydown.esc="isOpen = false"
        v-show="isOpen"
        class="z-10 fixed inset-0 transition-opacity"
      >
        <div
          @click="isOpen = false"
          class="absolute inset-0 bg-black opacity-50"
          tabindex="0"
        ></div>
      </div>
    </transition>
    <aside
      class="
        transform
        top-0
        left-0
        w-72
        bg-white
        fixed
        h-full
        overflow-auto
        ease-in-out
        transition-all
        duration-300
        z-30
      "
      :class="isOpen ? 'translate-x-0' : '-translate-x-full'"
    >
      <span
        @click="isOpen = false"
        class="flex w-full items-center p-4 border-b"
      >
        <img
          src="@/assets/img/logo_araruna.svg"
          alt="Logo"
          class="h-auto w-32 mx-auto"
        />
      </span>

      <span class="flex flex-col w-full items-center p-4 border-b">
        Logado como <b>{{ userEmail }}</b>
      </span>

      <span
        @click="navigate('/')"
        class="
          flex
          items-center
          p-4
          hover:bg-indigo-500 hover:text-white
          cursor-pointer
        "
        ><span class="mr-2">
          <!-- <font-awesome-icon icon="home" /> -->
        </span>
        <span>Início</span>
      </span>

      <span
        v-if="teacher?.permissoes?.admin || teacher?.permissoes?.operador"
        @click="navigate('/config')"
        class="
          flex
          items-center
          p-4
          hover:bg-indigo-500 hover:text-white
          cursor-pointer
        "
        ><span class="mr-2">
          <!-- <font-awesome-icon icon="home" /> -->
        </span>
        <span>Configurações</span>
      </span>

      <span
        v-if="teacher?.permissoes?.admin || teacher?.permissoes?.operador"
        @click="navigate('/alunos')"
        class="
          flex
          items-center
          p-4
          hover:bg-indigo-500 hover:text-white
          cursor-pointer
        "
        ><span class="mr-2">
          <!-- <img src="../assets/icon/students.svg" /> -->
        </span>
        <span>Alunos</span>
      </span>

      <span
        v-if="teacher?.permissoes?.admin || teacher?.permissoes?.operador"
        @click="navigate('/professores')"
        class="
          flex
          items-center
          p-4
          hover:bg-indigo-500 hover:text-white
          cursor-pointer
        "
        ><span class="mr-2">
          <!-- <img src="../assets/icon/teachers.svg" /> -->
        </span>
        <span>Professores</span>
      </span>

      <span
        v-if="teacher?.permissoes?.admin || teacher?.permissoes?.operador"
        @click="navigate('/turmas')"
        class="
          flex
          items-center
          p-4
          hover:bg-indigo-500 hover:text-white
          cursor-pointer
        "
        ><span class="mr-2">
          <!-- <img src="../assets/icon/groups.svg" /> -->
        </span>
        <span>Turmas</span>
      </span>

      <span
        v-if="teacher?.permissoes?.professor"
        @click="navigate('/minhas-turmas')"
        class="
          flex
          items-center
          p-4
          hover:bg-indigo-500 hover:text-white
          cursor-pointer
        "
        ><span class="mr-2">
          <!-- <img src="../assets/icon/my-groups.svg" /> -->
        </span>
        <span>Minhas turmas</span></span
      >
      <!-- <div class="fixed bottom-0 w-full"></div> -->
    </aside>
  </nav>
</template>

<script>
import { ref } from "vue";
import Firebase from "@/firebase";

export default {
  setup() {
    const loading = ref(true);
    const teacher = ref(null);

    const getTeacher = async (email) => {
      loading.value = true;
      const snap = await Firebase.getDocs(
        Firebase.collections.TEACHERS,
        Firebase.where("email", "==", email)
      );
      teacher.value = snap.docs[0].data();
      loading.value = false;
    };

    return {
      teacher,
      loading,
      getTeacher,
    };
  },

  data() {
    const auth = Firebase.getAuth();
    const { email } = auth.currentUser;

    return {
      userEmail: email,
      isOpen: false,
    };
  },
  methods: {
    drawer() {
      this.isOpen = !this.isOpen;
    },
    navigate(to) {
      this.$router.push(to);
      this.isOpen = false;
    },
    signOut() {
      Firebase.signOut()
        .then(() => {
          this.$router.push("login");
        })
        .catch((e) => console.error(e));
    },
  },
  watch: {
    isOpen: {
      immediate: true,
      handler(isOpen) {
        if (process.client) {
          if (isOpen) document.body.style.setProperty("overflow", "hidden");
          else document.body.style.removeProperty("overflow");
        }
      },
    },
  },
  mounted() {
    this.getTeacher(this.userEmail);

    document.addEventListener("keydown", (e) => {
      if (e.key == 27 && this.isOpen) this.isOpen = false;
    });
  },
};
</script>

<style scoped>
.dropdown:focus-within .dropdown-menu {
  opacity: 1;
  transform: translate(0) scale(1);
  visibility: visible;
}
</style>
